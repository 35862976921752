<template>
    <v-container>
        <base-section-heading
        title="about.principles.title">
        </base-section-heading>

        <Section
        v-for="section in sections"
        :key="section.key"
        :id="section.id"
        :title="section.title"
        :text="section.text"
        >
        </Section>
        
    </v-container>
</template>

<script>
import Section from '@/components/docs/Section'
export default {
    components: {
      Section,
    },
    data() {
        return {
            sections: [
                { 
                    key: 1,
                    id: 'plan-section',    
                    title: 'about.principles.section-1.title',
                    text: 'about.principles.section-1.text'
                },
                { 
                    key: 2, 
                    id: 'treatment-section',
                    title: 'about.principles.section-2.title',
                    text: 'about.principles.section-2.text' 
                },
            ],
        }
    }
}
</script>

<style scoped>
a:hover {
    background-color: lightyellow ;
    text-decoration: none
}
div >>> a:hover {
    background-color: lightyellow ;
    text-decoration: none
}
</style>